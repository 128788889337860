.editor

  @apply tracking-wider antialiased

  h1,
  h2,
  h3,
  h4
    @apply font-maison uppercase tracking-widest text-xs
    @apply mb-6

  p ~ h1,
  p ~ h2,
  p ~ h3,
  p ~ h4
    @apply mt-12

  &-small
    @apply text-copy_small_sm md:text-copy_small_base xl:text-copy_small_lg 2xl:text-copy_small_xl

  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a
    @apply underline decoration-1
    @apply underline-offset-[2px] hover:underline-offset-[4px]
    @apply md:underline-offset-[3px] hover:md:underline-offset-[5px]
    @apply transition-all duration-300

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-3 pl-8 lg:pl-10 relative
      &:before
        content: '— '
        @apply absolute left-0
