.tns-controls button
  @apply absolute z-20
  @apply -bottom-1
  @apply md:bottom-auto md:top-1/2 md:-translate-y-1/2
  @apply w-16 h-16
  @apply overflow-hidden
  text-indent: -1111rem

  &[data-controls="prev"]
    @apply left-0 md:-left-2 xl:left-2
    background: url("/assets/images/arrow-slider-prev.svg") no-repeat
    background-position: 30% 50%
    background-size: 14px 14px
    @screen md
      background-position: 50% 50%
      background-size: 24px 24px
  &[data-controls="next"]
    @apply right-0 md:-right-2 xl:right-2
    background: url("/assets/images/arrow-slider-next.svg") no-repeat
    background-position: 70% 50%
    background-size: 14px 14px
    @screen md
      background-position: 50% 50%
      background-size: 24px 24px
