@font-face {
  font-family: "Parent";
  src: url("/assets/fonts/parent-regular-pro.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Maison Neue";
  src: url("/assets/fonts/MaisonNeueMonoRegular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
