.header

  // .navigation__active &
  //   // @apply mix-blend-normal
  //   @apply text-black

  &.has-scrolled
    @apply bg-white
    // @apply mix-blend-normal
    .base--header
      // @apply bg-transparent
      // @apply transition-colors duration-500
